import {
  StandardModal,
  Txt,
  InlineLink,
  IconComponentType,
  ButtonLink,
  Icon,
} from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { Wifi, House } from '@vst/beam-icons/icons';

import styles from './styles.module.scss';

export const ManagePlanModal = ({
  isOpen,
  handleCloseModal,
  navigateToChangePlan,
}: {
  isOpen: boolean;
  handleCloseModal: () => void;
  navigateToChangePlan: () => void;
}) => {
  const { t } = useTranslation('NewProfile');

  const handleChangePlan = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.preventDefault();
    navigateToChangePlan();
  };

  const handleCancelPlan = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.preventDefault();
    return null;
  };

  return (
    <StandardModal
      size="xLarge"
      isOpen={isOpen}
      header={t('managePlan.header')}
      onClose={handleCloseModal}
      actions={{
        primary: { label: t('managePlan.close'), onClick: handleCloseModal },
      }}
    >
      <div className={styles['modal-content']}>
        <Txt variant="bodyLargeRegular">{t('managePlan.description')}</Txt>
        <div className={styles['action-cards']}>
          <PrimaryActionCard
            description={t('managePlan.changeActionDescription')}
            label={t('managePlan.changeActionLabel')}
            icon={Wifi}
            onClick={handleChangePlan}
          />
          <PrimaryActionCard
            description={t('managePlan.callActionDescription')}
            label={t('managePlan.callActionLabel')}
            icon={House}
            href={`tel:${t('managePlan.carePhoneNumber')}`}
          />
        </div>
        <SecondaryAction
          description={t('managePlan.cancelActionDescription')}
          link={t('managePlan.cancelActionLabel')}
          onClick={handleCancelPlan}
        />
        <SecondaryAction
          description={t('managePlan.otherActionDescription')}
          href={`tel:${t('managePlan.carePhoneNumber')}`}
          link={t('managePlan.carePhoneNumber')}
        />
      </div>
    </StandardModal>
  );
};

const PrimaryActionCard = ({
  description,
  label,
  icon,
  onClick,
  href = '#',
}: {
  description: string;
  label: string;
  icon: IconComponentType;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  href?: string;
}) => {
  return (
    <div className={styles['action-card']}>
      <Txt variant="bodyLargeRegular">{description}</Txt>
      <div className={styles['primary-action']} onClick={onClick}>
        <ButtonLink href={href} label={label} />
        <Icon icon={icon} color="blue_600" />
      </div>
    </div>
  );
};

const SecondaryAction = ({
  description,
  link,
  href = '#',
  onClick,
}: {
  description: string;
  link: string;
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}) => (
  <div className={styles['secondary-action']}>
    <Txt variant="bodySmallRegular">
      <span>{description}</span>
      <InlineLink href={href} onClick={onClick}>
        {link}
      </InlineLink>
    </Txt>
  </div>
);
