import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, InlineLink, Surface, Txt } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';

import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Platform } from '@mfe/shared/schema-types';

import { selectConfig } from '@mfe/shared/redux/config';
import { useDetectBrowser, useScreenResolution } from '@mfe/shared/util';
import { AddOnCard } from '@mfe/features/add-ons';
import {
  useShowAddonCustomerSupportMessage,
  ActiveAddonsError,
  useShowAddonsShop,
} from '@mfe/shared/components';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';

import styles from './Addons.module.scss';

export const AddonsCard = () => {
  const { t } = useTranslation(['Profile', 'Global']);
  const { goTo } = useNavigate();
  const {
    currentAddOns,
    error,
    loading: currentAddOnsLoading,
    showModal,
  } = useSelector(selectAddOns);

  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const browser = useDetectBrowser();

  const { platform, showRemoveAddonFlow } = useSelector(selectConfig);
  const showAddonsShop = useShowAddonsShop();

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const hasAddOns = Boolean(currentAddOns?.length);
  const showAddonCustomerSupportMessage = useShowAddonCustomerSupportMessage();

  const showLink = platform !== Platform.Web || browser === 'MobileWeb';

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const addOnsLink =
    accountType === AccountType.Residential
      ? t('Global:addOnsResidentialLink')
      : t('Global:addOnsSMBLink');

  if (currentAddOnsLoading) {
    return <AddonsLoading />;
  }

  if (error) {
    return (
      <ActiveAddonsError
        title={t('Profile:addOns.title')}
        description={t('Profile:addOns.error.description')}
        refreshButtonLabel={t('Profile:addOns.error.refreshButton')}
      />
    );
  }

  return (
    <div className={styles['container']}>
      <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
        {hasAddOns ? (
          <>
            <div className={styles['header']}>
              <Txt variant="heading5">{t('Profile:addOns.title')}</Txt>

              {showAddonsShop ? (
                <Button onClick={() => goTo('ShopAddons')} variant="secondary">
                  {t('Profile:addOns.shopAddonsButtonLabel')}
                </Button>
              ) : (
                <InlineLink
                  href={addOnsLink}
                  openInNewLocation={true}
                  onClick={(e: React.MouseEvent<HTMLElement>) => {
                    openLinkInNewTab(e, addOnsLink);
                  }}
                >
                  <Button variant="secondary" icon={OpenInNew} iconPos="right">
                    {t('Profile:addOns.seeMoreButton')}
                  </Button>
                </InlineLink>
              )}
            </div>
            <div className={styles['addons']}>
              {currentAddOns?.map((addOnOffer, index) => (
                <div className={styles['addons__item']} key={index}>
                  <AddOnCard
                    id={addOnOffer.productInstanceId}
                    addOnDescription={addOnOffer.description}
                    addOnName={addOnOffer.name}
                    addOnPrice={addOnOffer.price}
                    priceWithDiscount={addOnOffer.priceWithDiscount}
                    discountName={addOnOffer.discountName}
                    discountEndDate={addOnOffer.discountEndDate}
                    showManageLink={showRemoveAddonFlow}
                    showModal={showModal}
                    addOnState={addOnOffer.state}
                    isBundleAddOn={addOnOffer.isBundle}
                  />
                </div>
              ))}
            </div>
            {showAddonCustomerSupportMessage && (
              <div className={styles['footer']}>
                <Txt variant="bodySmallRegular">
                  {t('Profile:addOns.manageAddons')}
                  {showLink ? (
                    <InlineLink
                      data-cy="call-customer-support-phone-number"
                      variant="primary"
                      href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
                    >
                      {t('Global:callCustomerSupportPhoneNumberDisplay')}
                    </InlineLink>
                  ) : (
                    <Txt variant="bodySmallBold" component="span">
                      {t('Global:callCustomerSupportPhoneNumberDisplay')}
                    </Txt>
                  )}
                </Txt>
              </div>
            )}
          </>
        ) : (
          <NoAddons />
        )}
      </Surface>
    </div>
  );
};

const NoAddons = () => {
  const { t } = useTranslation(['Profile', 'Overview']);
  const { goTo } = useNavigate();

  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;
  return (
    <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
      <div className={styles['header']}>
        <Txt variant="heading5">{t('Profile:addOns.title')}</Txt>
      </div>
      <div className={styles['no-add-ons-container']}>
        <Txt variant="bodySmallRegular" color="subtle">
          {t('Profile:addOns.noAddOns')}
        </Txt>
        <Button
          variant="secondary"
          onClick={(e) => {
            e.preventDefault();
            goTo('ShopAddons');
          }}
        >
          {t('Overview:addOns.shopAddons')}
        </Button>
      </div>
    </Surface>
  );
};

const AddonsLoading = () => {
  return (
    <Surface data-cy="loading" className={styles['profile-loading-container']}>
      <div className={styles['loading-title-container']}>
        <div className={styles['loading-title']} />
      </div>

      <div className={styles['loading-content']}>
        <div className={styles['icon']} />
        <div className={styles['text-content']}>
          <div className={styles['title']} />
          <div className={styles['desc']} />
          <div className={styles['price']} />
        </div>
      </div>

      <div className={styles['loading-content']}>
        <div className={styles['icon']} />
        <div className={styles['text-content']}>
          <div className={styles['title']} />
          <div className={styles['desc']} />
          <div className={styles['price']} />
        </div>
      </div>
    </Surface>
  );
};
