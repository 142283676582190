import { Button, InlineLink, SectionAlert, Txt } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RefObject, useEffect } from 'react';

import {
  getCharacteristicValue,
  MarketingCharacteristics,
  useScreenResolution,
} from '@mfe/shared/util';
import {
  selectAddOns,
  selectCurrentShopAddon,
  selectSubscribedEasyCare,
  setSelectedEasyCareProductId,
} from '@mfe/to-be-migrated/redux/addOns';
import { GetCurrentAddOnsPayload, ProductType } from '@mfe/shared/schema-types';

import { getPriceAndDiscount } from '../addons-grid/utils';
import styles from './styles.module.scss';

export const EasyCareSelector = ({
  missingSelectionRef,
}: {
  missingSelectionRef: RefObject<HTMLDivElement>;
}) => {
  const { t } = useTranslation('ShopAddons');

  const { isMissingEasyCareSelection } = useSelector(selectAddOns);
  const currentShopAddon = useSelector(selectCurrentShopAddon);
  const subscribedEasyCareProduct = useSelector(selectSubscribedEasyCare);

  useEffect(() => {
    const element = missingSelectionRef.current;
    if (element && isMissingEasyCareSelection) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [isMissingEasyCareSelection]);

  const products =
    currentShopAddon?.package_types?.[0].product_types?.filter(
      (product) => product?.id !== subscribedEasyCareProduct?.productTypeId
    ) || [];

  if (products && products.length === 0) {
    return null;
  }

  return (
    <div className={styles['grouped-selector']}>
      <Txt variant="bodySmallRegular" color="subtle">
        {t('groupedEasyCare.rightTier')}
        <InlineLink
          ml="4px"
          href=""
          onClick={(e) => {
            e.preventDefault();
            const comparisonTable = document.getElementById(
              'tier-features-table'
            );

            if (comparisonTable) {
              comparisonTable.scrollIntoView({ behavior: 'smooth' });
            }
          }}
        >
          {t('groupedEasyCare.compareTierFeatures')}
        </InlineLink>
      </Txt>
      {isMissingEasyCareSelection && (
        <div ref={missingSelectionRef}>
          <SectionAlert
            id="no-add-on-selected"
            variant="error"
            label={t('groupedEasyCare.missingSelectionAlert')}
          />
        </div>
      )}

      <div className={styles['selector-options']}>
        {subscribedEasyCareProduct && (
          <SubscribedEasyCareProduct product={subscribedEasyCareProduct} />
        )}
        {products && <OffersList offers={products as ProductType[]} />}
      </div>
    </div>
  );
};

const SubscribedEasyCareProduct = ({
  product,
}: {
  product: GetCurrentAddOnsPayload;
}) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <ListItem
      product={{
        name: product?.name ?? '',
        price:
          product?.discountDuration && product?.discountEndDate
            ? t('priceAfterPromotion', {
                oldPrice: product?.price,
                totalPromotionDuration: product?.discountDuration,
              })
            : t('cart.pricePerMonth', { price: Number(product?.price) }),
        description: product?.description ?? '',
        id: product?.productTypeId ?? '',
      }}
    />
  );
};

const OffersList = ({ offers }: { offers: ProductType[] }) => {
  const { t } = useTranslation('ShopAddons');
  return (
    <div className={styles['offers-list']}>
      {offers?.map((offer: ProductType) => {
        if (!offer) return null;

        const { oldPrice = undefined, totalPromotionDuration = undefined } =
          getPriceAndDiscount(offer as ProductType);

        const description =
          getCharacteristicValue(
            offer?.marketing_copy?.translations?.[0]?.characteristics ?? [],
            MarketingCharacteristics.OFFER_DESCRIPTION
          ) ??
          offer?.description ??
          '';

        const easyCareOffer = {
          id: offer?.id ?? '',
          name: offer?.name ?? '',
          price: t('priceAfterPromotion', { oldPrice, totalPromotionDuration }),
          description,
        };

        return <ListItem key={offer.id} product={easyCareOffer} />;
      })}
    </div>
  );
};

type ListItemProduct = {
  id: string;
  name: string;
  price: string;
  description: string;
};

const ListItem = ({
  product: { price, id, name, description },
}: {
  product: ListItemProduct;
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ShopAddons');

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isSmall || isExtraSmall;

  const { selectedEasyCareProductId } = useSelector(selectAddOns);
  const isSelected = selectedEasyCareProductId === id;

  const existingEasyCareAddon = useSelector(selectSubscribedEasyCare);
  const isSubscribed = existingEasyCareAddon?.productTypeId === id;

  const buttonLabel = getButtonLabel();

  function getButtonLabel() {
    if (isSubscribed) {
      return t('groupedEasyCare.button.subscribed');
    }

    return isSelected
      ? t('groupedEasyCare.button.selected')
      : t('groupedEasyCare.button.select');
  }

  return (
    <div className={styles['product-container']}>
      <div className={styles['product-details']}>
        <div className={styles['name-and-offer']}>
          <Txt variant="bodySmallBold" mr="4px">
            {name}
          </Txt>
          <Txt component="span" variant="bodySmallRegular">
            {price}
          </Txt>
        </div>
        <Txt variant="smallRegular" color="subtle">
          {description}
        </Txt>
      </div>
      <Button
        buttonSize="medium"
        variant={isSubscribed ? 'primary' : 'secondary'}
        fluid={isMobile}
        className={styles['select-button']}
        onClick={(e) => dispatch(setSelectedEasyCareProductId(id))}
        pressed={isSelected}
        disabled={isSubscribed}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};
