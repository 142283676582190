import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Spinner } from '@vst/beam';

import {
  addDiscountToProductType,
  matchAddonWithFreeDiscount,
  selectAddOns,
  setCurrentShopAddonId,
  sortByCheapest,
} from '@mfe/to-be-migrated/redux/addOns';
import useNavigate from '@mfe/services/navigation';
import {
  AccountType,
  Characteristic,
  type ProductType,
} from '@mfe/shared/schema-types';
import { ShopAddonsLink } from '@mfe/legacy/mv/utils/Navigation';
import { selectConfig } from '@mfe/shared/redux/config';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  getCharacteristicValue,
  MarketingCharacteristics,
} from '@mfe/shared/util';
import { Storage, SESSION_STORAGE_VARIABLES } from '@mfe/shared/util';

import { AddonsGridErrorState } from './AddonsGridErrorState';
import { AddonsGridItem } from './AddonsGridItem';
import { getPriceAndDiscount } from './utils';
import { AddonsGridItemType } from './types';
import { AddonsGridEmptyState } from './AddonsGridEmptyState';
import styles from './index.module.scss';

const AddonsGrid = () => {
  const { availableAddons } = useSelector(selectAddOns);
  const { loading: userLoading } = useSelector(selectUser);
  const { disableSmbAddons, disableResidentialAddons } =
    useSelector(selectConfig);
  const { userInfo } = useSelector(selectUserInfo);
  const { t } = useTranslation('ShopAddons');
  const { loading, productTypes } = availableAddons;

  if (loading || userLoading) {
    return (
      <div className={styles['wrapper']}>
        <div className={styles['spinnerWrapper']}>
          <Spinner description={t('loading.description')} />
        </div>
      </div>
    );
  }

  if (!productTypes) {
    return <AddonsGridErrorState />;
  }

  const disabledAddons =
    userInfo.accountType === AccountType.Residential
      ? disableResidentialAddons
      : disableSmbAddons;

  const filteredProductTypes = productTypes.filter(
    (productType) =>
      !disabledAddons?.includes(productType.id) &&
      productType.id !==
        Storage.getItem(SESSION_STORAGE_VARIABLES.PURCHASED_PRODUCT_TYPE_ID)
  );

  if (filteredProductTypes.length === 0) {
    return <AddonsGridEmptyState />;
  }

  return (
    <div className={styles['wrapper']}>
      <div className={styles['grid']}>
        {filteredProductTypes.map((addon, i) =>
          addon.is_grouped_product ? (
            <GroupedAddon key={i} rootAddon={addon} />
          ) : (
            <StandaloneAddon key={i} addon={addon} />
          )
        )}
      </div>
    </div>
  );
};

const GroupedAddon = ({ rootAddon }: { rootAddon: ProductType }) => {
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();
  const { goTo } = useNavigate();

  const productTypes = rootAddon.package_types?.[0]
    .product_types as ProductType[];
  const sortedProducts = sortByCheapest(productTypes as ProductType[]);
  const cheapestProduct = sortedProducts[0];

  const cheapestProductDiscount = matchAddonWithFreeDiscount(
    cheapestProduct,
    rootAddon
  );
  const cheapestProductWithDiscount = addDiscountToProductType(
    cheapestProduct,
    cheapestProductDiscount
  );

  const title =
    getCharacteristicValue(
      cheapestProduct?.marketing_copy?.ui_behaviors
        ?.characteristics as Characteristic[],
      MarketingCharacteristics.OFFER_NAME
    ) ?? rootAddon.name;

  const description =
    getCharacteristicValue(
      rootAddon.marketing_copy.translations[0].characteristics,
      MarketingCharacteristics.OFFER_DESCRIPTION
    ) ??
    rootAddon.description ??
    '';

  const {
    price = undefined,
    oldPrice = undefined,
    promotions = [],
    totalPromotionDuration = undefined,
  } = getPriceAndDiscount(cheapestProductWithDiscount);

  const button = {
    text: t('selectAddon.addToPlanCTALabel', { title }),
    onClick: () => {
      dispatch(setCurrentShopAddonId(rootAddon.id));
      goTo(`${ShopAddonsLink.ShopAddons}/${ShopAddonsLink.Checkout}`);
    },
  };

  const props = {
    kind: rootAddon.kind,
    title,
    oldPrice,
    price,
    promotions,
    description,
    button,
    totalPromotionDuration,
    isGroupedProduct: true,
  };

  return <AddonsGridItem {...props} />;
};

const StandaloneAddon: React.FC<{ addon: ProductType }> = (props) => {
  const { addon } = props;
  const { t } = useTranslation('ShopAddons');
  const dispatch = useDispatch();
  const { goTo } = useNavigate();

  const badgeText = useMemo(() => {
    if (addon.kind === 'bep.ofm.product-types.satellite-internet-add-on') {
      return t('selectAddon.voiceBadgeText');
    }
    return undefined;
  }, [addon.kind, t]);

  const {
    price = undefined,
    oldPrice = undefined,
    promotions = [],
    totalPromotionDuration = undefined,
  } = getPriceAndDiscount(addon);

  const title =
    getCharacteristicValue(
      addon.marketing_copy.translations[0].characteristics,
      MarketingCharacteristics.OFFER_NAME
    ) ?? addon.name;

  const description =
    getCharacteristicValue(
      addon.marketing_copy.translations[0].characteristics,
      MarketingCharacteristics.OFFER_DESCRIPTION
    ) ??
    addon.description ??
    '';

  const button = {
    text: t('selectAddon.addToPlanCTALabel', { title }),
    onClick: () => {
      dispatch(setCurrentShopAddonId(addon.id));
      goTo(`${ShopAddonsLink.ShopAddons}/${ShopAddonsLink.Checkout}`);
    },
  };

  const passedProps: AddonsGridItemType = {
    kind: addon.kind,
    title,
    badgeText,
    oldPrice,
    price,
    promotions,
    description,
    button,
    totalPromotionDuration,
    isGroupedProduct: false,
  };

  return <AddonsGridItem {...passedProps} />;
};

export default AddonsGrid;
