import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Surface } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import {
  selectChangePlan,
  addPlanToCart,
} from '@mfe/to-be-migrated/redux/changePlan';
import { selectConfig } from '@mfe/shared/redux/config';
import { GetPlanOffersPayload } from '@mfe/shared/schema-types';
import {
  createAemUrl,
  AEMContentProvider,
  AemFeature,
} from '@mfe/shared/components';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { showTitleAddons } from '@mfe/shared/util';

import { PlanCard } from '../PlanCard';
import { TitleAddOns } from '../../shared';
import { PlanCardBody } from '../PlanCard/PlanCardBody';
import { PlanCardPrice } from '../PlanCard/PlanCardPrice';

import styles from './AvailablePlans.module.scss';

const BYPASS_DISCLOSURE_FOR_AVAILABLE_PLANS = undefined;

export const AvailablePlans = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('NewChangePlan');

  const { planOffersData, loading } = useSelector(selectChangePlan);
  const { aemBaseUrl, showBroadbandLabels } = useSelector(selectConfig);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  if (!planOffersData && !loading) {
    return null;
  }

  return (
    <Surface
      variant="secondary"
      className={styles['gridSurface']}
      data-cy="available-plans"
    >
      <Container
        fluid
        className={styles['gridContainer']}
        style={{
          justifyItems: 'center',
          maxWidth: 828,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      >
        {loading ? (
          <Loading />
        ) : (
          planOffersData?.map((plan: GetPlanOffersPayload, i: number) => {
            const {
              offerId,
              characteristics: { upi, serviceAreaId },
            } = plan;

            const handleClick = () => {
              dispatch(addPlanToCart({ newProductTypeId: plan.id }));
            };

            const showTitleAddOns = showTitleAddons(
              plan.characteristics.family,
              plan.characteristics.titleAddOns
            );

            return (
              <PlanCard
                variant="default"
                key={i}
                name={plan.characteristics.offerName}
                broadbandLabel={
                  showBroadbandLabels && (
                    <AEMContentProvider
                      src={createAemUrl({
                        baseUrl: aemBaseUrl,
                        locale: userLocale,
                        feature: AemFeature.PLAN_LABEL,
                        accountType,
                        additionalSelectors: ['pidi'],
                        suffixes: {
                          upi: upi ?? null,
                          serviceAreaId: serviceAreaId ?? null,
                          offerId,
                        },
                      })}
                      id={`plan-label-${plan.characteristics.offerName}`}
                      style={{ textAlign: 'left' }}
                    />
                  )
                }
              >
                <PlanCardBody
                  characteristics={plan.characteristics}
                  disclosure={
                    showBroadbandLabels
                      ? BYPASS_DISCLOSURE_FOR_AVAILABLE_PLANS
                      : plan.disclosure
                  }
                />
                {showTitleAddOns && (
                  <TitleAddOns
                    characteristic={plan.characteristics.titleAddOns || ''}
                  />
                )}
                <div>
                  <Button
                    buttonSize="large"
                    fluid={true}
                    variant="secondary"
                    onClick={handleClick}
                    mb="24px"
                  >
                    {t('plan.choosePlan')}
                  </Button>
                  <PlanCardPrice
                    price={plan?.price?.toString() ?? ''}
                    currency={plan.priceCurrency ?? undefined}
                  />
                </div>
              </PlanCard>
            );
          })
        )}
      </Container>
    </Surface>
  );
};

const Loading = () => {
  return (
    <>
      <PlanCard name="-">
        <div className={styles['loadingCharacteristics']} />
        <div className={styles['loadingButton']} />
        <div className={styles['loadingPrice']} />
        <div className={styles['loadingLabel']} />
      </PlanCard>
      <PlanCard name="-">
        <div className={styles['loadingCharacteristics']} />
        <div className={styles['loadingButton']} />
        <div className={styles['loadingPrice']} />
        <div className={styles['loadingLabel']} />
      </PlanCard>
    </>
  );
};
