import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { EmptyState } from '@vst/beam';
import { Alert } from '@vst/beam-icons/illustrative-icons';

import { selectPlanInfo } from '@mfe/to-be-migrated/redux/plan';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { Storage, SESSION_STORAGE_VARIABLES } from '@mfe/shared/util';
import { getClient } from '@mfe/services/graphql-client';
import { refreshContext } from '@mfe/to-be-migrated/redux/utils';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { selectChangePlan } from '@mfe/to-be-migrated/redux/changePlan';
import { GET_HAS_PENDING_TRANSITION } from '@mfe/to-be-migrated/redux/changePlan/requests';
import { VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID } from '@mfe/shared/util';
import {
  AddDataBoostSection,
  useCanChangePlan,
  useCanPlaceOrders,
} from '@mfe/shared/components';

import { Header } from './card-header';
import { VoiceContent } from './voice-content';
import { LoadingContent } from './loading';
import { PreinstallContent } from './preinstall-content';
import { DisconnectedContent } from './disconnected-content';
import { BuyMoreHistory } from './buy-more-history';

import styles from './styles.module.scss';

type Props = {
  id: string;
  isLoading: boolean;
  handleChangePlan?: () => void;
  handlePlanDetailsClick: () => void;
  handleNavigateToPlanPage: () => void;
  showFooter?: boolean;
  children?: React.ReactNode;
};

export const UsageSummaryCard = ({
  id,
  isLoading,
  handleChangePlan,
  handlePlanDetailsClick,
  showFooter,
  children,
}: Props) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('Error');

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);

  const { hasActivationError } = useSelector(selectChangePlan);

  const {
    errors: { usage: usageErrors },
    data: {
      characteristics: { id: productTypeId },
    },
  } = useSelector(selectPlanInfo);

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    user: {
      auth: { tokenInfo },
    },
  } = useSelector(selectUser);

  const [client, setClient] = useState(getClient(tokenInfo, userLocale));

  useEffect(() => {
    setClient(getClient(tokenInfo, userLocale));
  }, [tokenInfo, userLocale]);

  const { isDisconnected } = useCanPlaceOrders();
  const canChangePlan = useCanChangePlan();

  useQuery(GET_HAS_PENDING_TRANSITION, {
    skip: canChangePlan,
    pollInterval: 5 * 60 * 1000,
    fetchPolicy: 'network-only',
    client,
    variables: { refetchData: true },
    onCompleted: (data: {
      getHasPendingTransition: { isChangePlanDisabled: boolean };
    }) => {
      if (data?.getHasPendingTransition?.isChangePlanDisabled === false) {
        Storage.removeItem(SESSION_STORAGE_VARIABLES.CHANGE_PLAN_SUCCESS);
        dispatch(refreshContext());
      }
    },
  });

  const showError = !isLoading && usageErrors;

  if (isPreInstall) {
    return (
      <div data-cy={id} className={`${styles['containerWhite']} usage-summary`}>
        <Header
          handleChangePlan={handleChangePlan}
          handlePlanDetailsClick={handlePlanDetailsClick}
          isChangePlanDisabled={!canChangePlan}
        />
        <PreinstallContent />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div data-cy={id} className={styles['containerWhite']}>
        <LoadingContent />
      </div>
    );
  }

  if (isDisconnected) {
    return (
      <div data-cy={id} className={`${styles['containerWhite']} usage-summary`}>
        <DisconnectedContent />
      </div>
    );
  }

  if (showError) {
    return (
      <div data-cy={id} className={styles['containerWhite']}>
        <EmptyState
          icon={Alert}
          title={t('title')}
          description={t('message', { error: t('usage_usagesummary') })}
        />
      </div>
    );
  }

  const isVoice = productTypeId === VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID;
  if (isVoice) {
    return (
      <div data-cy={id} className={`${styles['containerWhite']} usage-summary`}>
        <Header
          handleChangePlan={handleChangePlan}
          handlePlanDetailsClick={handlePlanDetailsClick}
          isChangePlanDisabled={!canChangePlan}
        />
        <VoiceContent />
      </div>
    );
  }

  return (
    <div data-cy={id} className={`${styles['containerWhite']} usage-summary`}>
      <Header
        handleChangePlan={handleChangePlan}
        handlePlanDetailsClick={handlePlanDetailsClick}
        isChangePlanDisabled={!canChangePlan || hasActivationError}
      />

      {children}

      {showFooter && (
        <>
          <AddDataBoostSection />
          <BuyMoreHistory />
        </>
      )}
    </div>
  );
};
